import React from "react"
import styled from "styled-components"

const HeaderEl = styled.header`
  margin: 0 0 2rem;
  h1 {
    hyphens: auto;
  }
  .meta {
    margin-bottom: 2rem;
  }
`

const Headline = ({ type, title, published, timeToRead, image }) => {
  let readTimeString = ""
  if (timeToRead === 1) {
    readTimeString = "Eine Minute Lesezeit"
  } else {
    readTimeString = timeToRead + " Minuten Lesezeit"
  }
  let publishedDate = new Date(published)
  let publishedDateLocale = publishedDate.toLocaleDateString()

  return (
    <HeaderEl>
      <h1>{title}</h1>
      <div className="meta">
        {type === "post" && <span>{readTimeString}</span>}{" "}
        {published && <time dateTime={published}>{publishedDateLocale}</time>}
      </div>
    </HeaderEl>
  )
}
export default Headline
