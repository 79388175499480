import React from "react"
import styled from "styled-components"
import { lightTheme } from "../../utils/theme"
import { rgba } from "polished"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAltSmile, faBomb } from "@fortawesome/pro-solid-svg-icons"

const Message = styled.div`
  header,
  p {
    padding: 0.3rem 1rem;
    margin: 0;
  }
  header {
    font-weight: 900;
    font-size: 1.4em;
  }
  &.success {
    border: 1px solid ${rgba(lightTheme.success, 0.2)};
    background-color: ${rgba(lightTheme.success, 0.1)};
    header {
      background-color: ${rgba(lightTheme.success, 0.2)};
    }
    color: ${lightTheme.success};
  }
  &.error {
    border: 1px solid ${rgba(lightTheme.purple, 0.2)};
    background-color: ${rgba(lightTheme.purple, 0.1)};
    header {
      background-color: ${rgba(lightTheme.purple, 0.2)};
    }
    color: ${lightTheme.purple};
  }
`

export default props => {
  if (props.showMessage) {
    return (
      <Message className={props.messageSuccess ? "success" : "error"}>
        <header>
          {props.messageSuccess ? (
            <FontAwesomeIcon icon={faCommentAltSmile} />
          ) : (
            <FontAwesomeIcon icon={faBomb} />
          )}
          {" " + props.messageHeadline}
        </header>
        <p>{props.messageContent} </p>
      </Message>
    )
  } else return null
}
