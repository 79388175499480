import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Note: You can change "images" to whatever you'd like.

const normalQuery = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 2420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Image = props => (
  <StaticQuery
    query={normalQuery}
    render={data => {
      const image = data.images.edges.find(n => {
        let fn = props.filename.replace("../", "")
        return n.node.relativePath.includes(fn)
      })
      if (!image) {
        return null
      }

      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      if (props.isHeaderImage === true) {
        return (
          <Img
            alt={props.alt}
            fluid={image.node.childImageSharp.fluid}
            style={{ maxHeight: "80vh" }}
          />
        )
      }
      return (
        <Img
          alt={props.alt}
          fluid={image.node.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
      )
    }}
  />
)

export default Image
