import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Headline from "../components/headline"
import InquiryForm from "../components/form/inquiry-form"
import Image from "../components/image"
import styled from "styled-components"

const Main = styled.div`
  grid-area: image;
`

export default function Template({ data }) {
  const { html, fields, timeToRead } = data.markdownRemark
  const fieldsObj = JSON.parse(fields.frontmatter)
  return (
    <Layout>
      <SEO
        title={fieldsObj.title}
        description={fieldsObj.description}
        article={fieldsObj.type === "post"}
        image={fieldsObj.image}
        pathname={fieldsObj.path}
      />
      {fieldsObj.image && !fieldsObj.hideImage && (
        <Main>
          <Image
            isHeaderImage
            filename={fieldsObj.image}
            alt={fieldsObj.title}
          />
        </Main>
      )}
        <main>
          <Headline
            type={fieldsObj.type}
            title={fieldsObj.title}
            published={fieldsObj.publishedDate}
            timeToRead={timeToRead}
            image={fieldsObj.image}
          />
          <Container>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Container>
          {fieldsObj.appendForm && <InquiryForm />}
        </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      fields {
        frontmatter
      }
    }
    pageImg: file(relativePath: { eq: "assets/toni-404.jpg" }) {
      childImageSharp {
        fixed(width: 2100) {
          src
        }
      }
    }
  }
`
