import React, { useState } from "react"
import styled from "styled-components"
import { lightTheme } from "../../utils/theme"
import { rgba } from "polished"
import Container from "../container"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import de from "date-fns/locale/de"
import getDay from "date-fns/getDay"
import Message from "./message"

registerLocale("de", de)

const Group = styled.div`
  box-sizing: border-box;
  margin: 0.25rem 0 0.75rem 0;
  align-items: center;
  &.hunney {
    display: none;
  }

  label {
    font-size: 0.8rem;
    font-weight: 900;
    display: block;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  input,
  textarea,
  select {
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: inline-flex;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    border-radius: 0.25em;
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.4);
    }
    &:focus {
      border-color: ${rgba(lightTheme.success, 0.5)};
      box-shadow: inset 0 0.0625em 0.3em ${rgba(lightTheme.success, 0.2)};
    }
    &:invalid:not(:focus):not(:placeholder-shown) {
      border-color: ${rgba(lightTheme.purple, 0.5)};
      box-shadow: inset 0 0.0625em 0.3em ${rgba(lightTheme.purple, 0.2)};
    }
  }
`

const InquiryForm = () => {
  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }

  let nextMonday = new Date()
  nextMonday.setDate(
    nextMonday.getDate() + ((((7 - nextMonday.getDay()) % 7) + 1) % 7)
  )

  const [startDate, setStartDate] = useState(nextMonday)
  const [message, setMessage] = useState({
    show: false,
    success: false,
    headline: "",
    msg: "",
  })

  const disableFormFields = target => {
    const fields = target.elements
    for (let i = 0, len = fields.length; i < len; ++i) {
      fields[i].disabled = true
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    const GATEWAY_URL = "https://forms.logopaedie-ludwig-ott.de/"
    let form = e.target
    let values = new FormData(form)
    try {
      fetch(GATEWAY_URL, {
        method: "POST",
        mode: "cors",
        body: values,
      }).then(response => {
        if (response.ok) {
          setMessage({
            show: true,
            success: true,
            headline: "Danke für Ihre Terminanfrage!",
            msg:
              "Vielen Dank, wir haben Ihre Terminanfrage bekommen. Bitte geben Sie uns einige Stunden Zeit, um Ihre Anfrage zu bearbeiten. Wir melden uns!",
          })
          disableFormFields(form)
          window.dataLayer.push({ event: "inquiry-form-sent" })
        } else {
          setMessage({
            show: true,
            success: false,
            headline: "Ohoh!",
            msg:
              "Sorry! Irgendetwas ist schief gegangen, am besten Sie rufen uns an: 06181 4270777",
          })
          window.dataLayer.push({ event: "inquiry-form-failed" })
        }
        return response.text()
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Container size="narrow">
      <form onSubmit={onSubmit}>
        <Group>
          <label htmlFor="salutation">Anrede</label>
          <select
            name="salutation"
            id="salutation"
            defaultValue="Bitte auswählen"
          >
            <option disabled>Bitte auswählen</option>
            <option>Frau</option>
            <option>Herr</option>
          </select>
        </Group>
        <Group>
          <label htmlFor="fname">Vorname</label>
          <input
            type="text"
            id="fname"
            name="fName"
            placeholder="Maxi"
            autoComplete="given-name"
            required
          />
        </Group>
        <Group>
          <label htmlFor="lname">Nachname</label>
          <input
            type="text"
            id="lname"
            name="lName"
            placeholder="Mustermann"
            autoComplete="family-name"
            required
          />
        </Group>
        <Group className="hunney">
          <label htmlFor="blob">Check</label>
          <input
            type="text"
            id="blob"
            name="llo"
            placeholder="Bitte leer lassen."
          />
        </Group>
        <Group>
          <label htmlFor="date">Wunschtermin</label>
          <DatePicker
            name="date"
            id="date"
            required
            locale="de"
            selected={startDate}
            filterDate={isWeekday}
            dateFormat="d.M.Y"
            onChange={date => setStartDate(date)}
          />
        </Group>
        <Group>
          <label htmlFor="time">Tageszeit</label>
          <select name="timeOfDay" id="time" defaultValue="Bitte auswählen">
            <option disabled>Bitte auswählen</option>
            <option>vormittags</option>
            <option>mittags</option>
            <option>nachmittags</option>
          </select>
        </Group>
        <Group>
          <label htmlFor="mail">E-Mail-Adresse</label>
          <input
            type="email"
            name="mail"
            id="mail"
            placeholder="maxi@mustermann.de"
            autoComplete="mail"
            required
          />
        </Group>
        <Group>
          <label htmlFor="tel">Telefonnummer</label>
          <input
            type="tel"
            id="tel"
            name="phone"
            placeholder="01512345678"
            autoComplete="tel"
            required
          />
        </Group>
        <Group>
          <label htmlFor="msg">Ihre Nachricht</label>
          <textarea
            type="msg"
            name="msg"
            id="msg"
            defaultValue="Hallo! Bitte teilen Sie mir mit, ob der gewünschte Termin noch frei ist."
          />
        </Group>
        <button type="submit" className="success">
          Anfrage absenden
        </button>
      </form>
      <Message
        showMessage={message.show}
        messageSuccess={message.success}
        messageHeadline={message.headline}
        messageContent={message.msg}
      />
    </Container>
  )
}

export default InquiryForm
